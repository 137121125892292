(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/debounce-on-event/assets/javascripts/debounce-on-event.js') >= 0) return;  svs.modules.push('/components/lb-ui/debounce-on-event/assets/javascripts/debounce-on-event.js');
"use strict";


const {
  useRef,
  useEffect
} = React;
const DebounceOnEvent = props => {
  const {
    children,
    delayMS,
    hasActionsAfterUnmount,
    onEventDelayed,
    onEventDirectly
  } = props;
  const timerRef = useRef(null);
  const killTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      if (!hasActionsAfterUnmount) {
        killTimer();
      }
    };
  }, []);
  if (typeof children !== 'function') {
    throw new Error('<DebounceOnEvent/>: Children must be a function');
  }
  const handleEvent = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    killTimer();
    if (isMountedRef.current || hasActionsAfterUnmount) {
      if (typeof onEventDirectly === 'function') {
        onEventDirectly(...args);
      }
      if (typeof onEventDelayed === 'function') {
        if (isMountedRef.current) {
          timerRef.current = setTimeout(() => onEventDelayed(...args), delayMS);
        } else {
          onEventDelayed(...args);
        }
      }
    }
  };
  return children({
    handleEvent
  });
};
DebounceOnEvent.propTypes = {
  children: PropTypes.func.isRequired,
  delayMS: PropTypes.number,
  hasActionsAfterUnmount: PropTypes.bool,
  onEventDelayed: PropTypes.func,
  onEventDirectly: PropTypes.func
};
DebounceOnEvent.defaultProps = {
  delayMS: 500,
  hasActionsAfterUnmount: false,
  onEventDelayed: null,
  onEventDirectly: null
};
setGlobal('svs.components.lbUi.DebounceOnEvent', DebounceOnEvent);

 })(window);